.home {
  width: 100%;
  align-items: center;
  font-family: "Arial", sans-serif;
  color: #3e497a;
}

.about {
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #21325e;
  color: #f0f0f0;
}

.about h2 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 80px;
  color: #e9d35b;
  height: 50px;
}

.about .prompt {
  width: 40%;
  font-size: 30px;
}
.prompt svg {
  font-size: 60px;
  margin: 5px;
  color: white;
}

.skills {
  font-size: 35px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.skills h1 {
  color: #3e497a;
}

.skills .list {
  list-style: none;
  width: 60%;
}

.list span {
  font-size: 20px;
}

@media only screen and (max-width: 600px) {
  .about h2 {
    font-size: 40px;
  }

  .about .prompt {
    margin-top: 10px;
    font-size: 20px;
    width: 80%;
  }

  .skills {
    text-align: center;
  }
  .list {
    padding: 0;
  }

  .skills h2 {
    font-size: 30px;
  }
}